<template>
  <component
    :is="tag"
    :class="classes"
    :style="styles"
    class="base-body text-body-1"
    v-bind="$attrs"
  >
    <slot>
      {{ text }}
    </slot>
  </component>
</template>

<script>
// Mixins
import Heading from '@/mixins/heading';

export default {
  name: 'BaseBody',

  mixins: [Heading],

  // inject: ['theme'],

  props: {
    maxWidth: {
      type: [Number, String],
      default: undefined,
    },
    space: {
      type: [Number, String],
      default: 10,
    },
    tag: {
      type: String,
      default: 'p',
    },
    text: { type: String, default: null },
  },

  computed: {
    classes() {
      return [
        'grey--text',
        'text--darken-1',
        `text-${this.heading.align}`,
        `mb-${this.space}`,
      ];
    },
    styles() {
      return {
        maxWidth: `${this.maxWidth}px`,
      };
    },
  },
};
</script>
